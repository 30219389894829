@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.App {
  text-align: center;
  font-family: 'Bebas Neue', cursive;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-size: 3rem;
}
.sauce-card {
  min-height: 200px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p {
  font-size: 1.1rem;
}

.jumbo {
  position: relative; /* Ensure the pseudo-element is positioned relative to .jumbo */
  background-color: rgba(153, 41, 30, 0.9);
  color: white;
  padding: 3.5rem 1rem;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.jumbo::after {
  content: "";
  background-image: url('./assets/allBottles.png');
  background-size: cover;
  background-position: center;
  opacity: 0.5; /* Adjust the opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Ensure the image is behind the content */
}

.modal-dialog {
  height: 100%;
  color: white;
  font-family: 'Bebas Neue', cursive;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.peppers-image {
  width: 100%;
  max-width: 250px !important;
  margin-bottom: 1rem;
}

.modal-fullscreen .modal-body {
  display: flex;
}

.modal-content {
  background-color: rgba(39,37,37, 0.95) !important;
  color: white;
  padding: 1rem 1rem;
  text-align: center;
  margin-bottom: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden; 
  height: 100%;
}

button.btn-close {
  background-color: white;
}

.jumbo img {
  max-width: 50%;
  margin-bottom: 1rem;
}

.belly-logo {
  max-width: 50% !important;
  max-height: 100vh;
  margin-bottom: 1rem;
}

#card-fields-container {
  color: white !important;
}

.thank-you {
  margin-top: 20%;
}

.sauce-card {
  background-color: rgb(153, 41, 30) !important;
  color: white !important;
  cursor: pointer;
  color: #4e342e;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  min-height: 180px;
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 1s;
  padding: 10px 0 !important;
  border-radius: 5px !important;
}

.big h2{
  font-size: 3.5rem;
}

.big h3{
  font-size: 2rem;
}

.big p{
  font-size: 1.5rem;
}

.section-header {
  text-align: left !important;
  margin-bottom: 0 !important;
  font-size: 4.5rem;
}

.sauce-card:hover {
  transform: scale(1.05);
}

.location-card img {
  width: 100%;
  max-width: 200px;
}

.sauce-image {
  max-width: 100%;
  margin-bottom: 1rem;
  max-height: 75vh;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.quantity {
  width: 10% !important;
}

.cart-item {
  display: flex;
  justify-content: space-between; /* Pushes the item-total to the right */
  padding: 10px 0; /* Optional: Add some padding between items */
  min-width: 400px;
}

.item-quantity {
  text-align: left; /* Aligns item-quantity on the left */
}

.item-total {
  text-align: right; /* Aligns item-total on the right */
}
.belly-container {
  overflow: hidden;
  margin: 0 5% !important;
}
.cart-modal {
  justify-content: center;
  width: 100%;
}
.sauce-modal-image {
  max-height: 100% !important;
}
.toast {
  background-color: rgba(25, 135, 84, 0.9) !important;
  color: white !important;
  width: 200px !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* media breakpoints */
@media (max-width: 1400px) {
  /* .sauce-modal-image {
    max-width: 80% !important;
  } */
}
@media (max-width: 900px) {
  .big h2{
    font-size: 3;
  }

  .big h3{
    font-size: 1.75rem;
  }

  .big p{
    font-size: 1.25rem;
  }

  .section-header {
    font-size: 2.5rem;
  }

  .jumbo img {
    max-width: 60%;
  }
}

@media (max-width: 700px) {
  .row {
    display: block !important;
  }

  .belly-container {
    margin: 0 !important;
  }
  
  .big h2{
    font-size: 2.5rem;
  }

  .big h3{
    font-size: 1.5rem;
  }

  .big p{
    font-size: 1rem;
  }

  /* .sauce-modal-image {
    max-width: 60% !important;
  } */

  .quantity {
    width: 20% !important;
  }
}

@media (max-width: 400px) {
  .row {
    display: block !important;
  }

  .big h2{
    font-size: 2rem;
  }

  .big h3{
    font-size: 1rem;
  }

  .big p{
    font-size: .75rem;
  }

  /* .sauce-modal-image {
    height: 75% !important;
    max-width: 100% !important;
  } */
  .jumbo img {
    max-width: 100%;
  }

  .form-select {
    padding: .375rem .15rem .375rem .35rem !important;
  }

  .peppers-image {
    width: 100%;
    max-width: 150px !important;
    margin-bottom: 1rem;
  }

  .toast {
    width: 100px !important;
  }

  .toast h5 {
    font-size: 1rem;
  }
  .cart-item {
    min-width: 150px;
  }
}

@media (max-width: 300px) {
  .card-body {
    padding: 0 !important;
  }

  .big h2{
    font-size: 1.5rem;
  }

  .big h3{
    font-size: .75rem;
  }

  .big p{
    font-size: .5rem;
  }

  p {
    font-size: .75rem;
  }

 h4 {
    font-size: 1rem !important;
  }

  .toast {
    width: 75px !important;
  }

  .toast h5 {
    font-size: .75rem;
  }
}
